import {
  AreaChartOutlined,
  BranchesOutlined,
  ControlOutlined,
  DashboardOutlined,
  HomeOutlined,
  MoneyCollectOutlined,
  PicCenterOutlined,
  WechatOutlined
} from "@ant-design/icons";
import React, { ComponentType, ReactNode } from "react";
import { MenuType } from "../constants";

export const AdminRoutes: Route[] = [
  {
    id: 12010000,
    path: "dashboard",
    name: "首页",
    type: MenuType.Page,
    icon: <DashboardOutlined />,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12020001,
        path: "Operationaldata/index.html",
        name: "运营数据统计",
        type: MenuType.Page,
        permission: ["page:Operationaldata"],
        component: () => import("../pages/admin/dashboard/index")
      },
      {
        id: 12020002,
        path: "FinancialStatements/index.html",
        name: "财务账单统计",
        type: MenuType.Page,
        permission: ["page:FinancialStatements"],
        component: () => import("../pages/admin/FinancialStatements/index")
      },
      {
        id: 12020003,
        path: "performanceStatistics/index.html",
        name: "公司业绩统计",
        permission: ["page:performanceStatistics"],
        type: MenuType.Page,
        component: () => import("../pages/admin/performanceStatistics/index")
      },
      {
        id: 12020004,
        path: "map/index.html",
        name: "地图找房",
        type: MenuType.Page,
        permission: ["page:Operationaldata"],
        component: () => import("../pages/admin/map/index")
      }
    ]
  },
  {
    id: 12020000,
    path: "housing",
    name: "房源管理",
    type: MenuType.Directory,
    permission: "directory:housing",
    icon: <HomeOutlined />,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12020100,
        path: "full-rent/index.html",
        name: "整租管理",
        type: MenuType.Page,
        permission: ["page:housing:full-rent"],
        component: () => import("../pages/admin/housing/full-rent/index")
        // NotRefresh: true
      },
      {
        id: 12020200,
        path: "share-rent/index.html",
        name: "合租管理",
        type: MenuType.Page,
        permission: ["page:housing:share-rent"],
        component: () => import("../pages/admin/housing/share-rent/index")
        // NotRefresh: true
      },
      {
        id: 12020300,
        path: "center-rent/index.html",
        name: "集中整租",
        type: MenuType.Page,
        permission: ["page:housing:center-rent"],
        component: () => import("../pages/admin/housing/center-rent/index")
        // NotRefresh: true
      },
      {
        id: 12020400,
        path: "reserve-housing/index.html",
        name: "潜在业主",
        type: MenuType.Page,
        permission: ["page:housing:reserve-housing"],
        component: () => import("../pages/admin/housing/reserve-housing/index")
        // NotRefresh: true
      },
      {
        id: 12020401,
        path: "reserve-housing/insert.html",
        name: "潜在业主新增",
        hideMenu: true,
        type: MenuType.Page,
        permission: ["page:housing:reserve-housing"],
        component: () => import("../pages/admin/housing/reserve-housing/edit")
      },
      {
        id: 12020402,
        path: "reserve-housing/detail.html",
        name: "潜在业主详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/reserve-housing/detail")
      },
      {
        id: 12020403,
        path: "reserve-housing/update.html",
        name: "潜在业主修改",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/reserve-housing/edit")
      },
      {
        id: 12020500,
        path: "reserve-customers/index.html",
        name: "潜在租客",
        type: MenuType.Page,
        permission: ["page:housing:reserve-customers"],
        component: () =>
          import("../pages/admin/housing/reserve-customers/index")
        // NotRefresh: true
      },
      {
        id: 12020501,
        path: "reserve-customers/insert.html",
        name: "潜在租客新增",
        hideMenu: true,
        type: MenuType.Page,
        component: () =>
          import("../pages/admin/housing/reserve-customers/edit"),
        NotRefresh: true
      },
      {
        id: 12020502,
        path: "reserve-customers/update.html",
        name: "潜在租客修改",
        hideMenu: true,
        type: MenuType.Page,
        component: () =>
          import("../pages/admin/housing/reserve-customers/edit"),
        NotRefresh: true
      },
      {
        id: 12020503,
        path: "reserve-customers/detail.html",
        name: "潜在租客详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () =>
          import("../pages/admin/housing/reserve-customers/detail")
      },
      {
        id: 12020600,
        path: "detail.html",
        name: "房源详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/detail"),
        NotRefresh: true
      },
      {
        id: 12020601,
        path: "before-detail.html",
        name: "退房前房源详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/detail"),
        NotRefresh: true
      },
      {
        id: 12020701,
        path: "renewal.html",
        name: "房东续约详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () =>
          import(
            "../pages/admin/housing/detail/components/renewal/details/renewal"
          )
      },
      {
        id: 12020700,
        path: "details.html",
        name: "房租详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/details")
      },
      {
        id: 12020710,
        path: "exitdetail.html",
        name: "退房房租详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/exitdetail")
      },
      {
        id: 120207001,
        path: "debtnotification.html",
        name: "欠款详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/debtnotification")
      },
      {
        id: 12020800,
        path: "insert.html",
        name: "登记房东",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/edit")
      },
      {
        id: 12020900,
        path: "update.html",
        name: "修改房东",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/edit"),
        NotRefresh: true
      },
      {
        id: 12020910,
        path: "synchronization.html",
        name: "整租同步58",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/synchronization"),
        NotRefresh: true
      },
      {
        id: 12020992,
        path: "online.html",
        name: "整租上传找房",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/online"),
        NotRefresh: true
      },
      {
        id: 12020991,
        path: "changeonline.html",
        name: "整租上传找房修改",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/changeonline"),
        NotRefresh: true
      },
      {
        id: 12020912,
        path: "cotenancy.html",
        name: "合租同步58",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/cotenancy"),
        NotRefresh: true
      },
      {
        id: 12020919,
        path: "room-push.html",
        name: "合租房间同步58",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/room-push"),
        NotRefresh: true
      },
      {
        id: 12020911,
        path: "changes-ynchronization.html",
        name: "修改同步",
        hideMenu: true,
        type: MenuType.Page,
        component: () =>
          import("../pages/admin/housing/changes-ynchronization"),
        NotRefresh: true
      },
      {
        id: 12020914,
        path: "changes-cotenancy.html",
        name: "合租修改同步",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/changes-cotenancy"),
        NotRefresh: true
      },
      {
        id: 12020921,
        path: "changes-room-push.html",
        name: "合租房间修改同步",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/changes-room-push"),
        NotRefresh: true
      },
      {
        id: 12021000,
        path: "renovation-detail.html",
        name: "装修信息",
        hideMenu: true,
        type: MenuType.Page,
        component: () =>
          import("../pages/admin/housing/detail/components/renovation/detail")
      },
      {
        id: 12021100,
        path: "bargain-save.html",
        name: "定金",
        hideMenu: true,
        type: MenuType.Page,
        component: () =>
          import("../pages/admin/housing/full-rent/bargain/index")
      },
      {
        id: 12021200,
        path: "room-detail.html",
        name: "房间详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/room/detail"),
        NotRefresh: true
      },
      {
        id: 15803022,
        path: "before-room-detail.html",
        name: "退房前房间详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/room/detail"),
        NotRefresh: true
      },
      {
        id: 12020702,
        path: "tenants-contract.html",
        name: "租客续约详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () =>
          import("../pages/admin/room/components/renewal/details/renewal")
      },
      {
        id: 12021210,
        path: "tenant-exit-detail.html",
        name: "租客退房详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () =>
          import("../pages/admin/housing/detail/components/tenant-exit-detail")
      },
      {
        id: 12021211,
        path: "tenant-sublet.html",
        name: "租客转租详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () =>
          import("../pages/admin/housing/detail/components/tenant-sublet")
      },
      {
        id: 12021212,
        path: "tenant-print.html",
        name: "租客退房打印",
        hideMenu: true,
        type: MenuType.Page,
        component: () =>
          import("../pages/admin/housing/detail/components/tenant-print")
      },
      {
        id: 12021300,
        path: "tenant-bind.html",
        name: "登记租客",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/edit-tenant"),
        NotRefresh: true
      },
      {
        id: 12021301,
        path: "tenant-edit.html",
        name: "修改租客",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/housing/edit-tenant")
      },
      {
        path: "landlord-checkout.html",
        id: 12021400,
        type: MenuType.Page,
        name: "房东退房",
        hideMenu: true,
        component: () => import("../pages/admin/housing/landlord-checkout"),
        NotRefresh: true
      },
      {
        path: "edit-landlord-checkout.html",
        id: 120214001,
        type: MenuType.Page,
        name: "修改房东退房",
        hideMenu: true,
        component: () => import("../pages/admin/housing/landlord-checkout"),
        NotRefresh: true
      },
      {
        path: "tenant-checkout.html",
        id: 12021500,
        type: MenuType.Page,
        name: "租客退房",
        hideMenu: true,
        component: () => import("../pages/admin/housing/tenant-checkout"),
        NotRefresh: true
      },
      {
        path: "tenant-sublease.html",
        id: 12021600,
        type: MenuType.Page,
        name: "租客转租",
        hideMenu: true,
        component: () => import("../pages/admin/housing/tenant-sublease")
      },
      {
        path: "tenant-changehouse.html",
        id: 12021700,
        type: MenuType.Page,
        name: "租客换房",
        hideMenu: true,
        component: () =>
          import("../pages/admin/room/components/changeHouse/edit"),
        NotRefresh: true
      },
      {
        path: "tenant-subleasehouse.html",
        id: 12021800,
        type: MenuType.Page,
        name: "租客转租",
        hideMenu: true,
        component: () =>
          import("../pages/admin/room/components/SubleaseHouse/edit"),
        NotRefresh: true
      }
    ]
  },
  {
    id: 12040000,
    path: "finance",
    name: "财务管理",
    type: MenuType.Directory,
    permission: "directory:finance",
    icon: <MoneyCollectOutlined />,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12040100,
        path: "finance.html",
        name: "财务管理",
        type: MenuType.Page,
        permission: ["page:finance:finance"],
        component: () => import("../pages/admin/finance/finance/index")
        // NotRefresh: true
      },
      {
        id: 12040101,
        path: "insert.html",
        name: "财务管理新增",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/finance/finance/edit")
      },
      {
        id: 12040110,
        path: "update.html",
        name: "财务管理修改",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/finance/finance/edit")
      },
      {
        id: 12040120,
        path: "detail.html",
        name: "财务管理详情",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/finance/finance/detail")
      },
      {
        id: 12040200,
        path: "debt.html",
        name: "欠款管理",
        type: MenuType.Page,
        component: () => import("../pages/admin/finance/debt/index")
        // NotRefresh: true
      },
      {
        id: 12040300,
        path: "income-expense/index.html",
        name: "收支计划",
        type: MenuType.Page,
        component: () =>
          import("../pages/admin/finance/income-expense-plan/index")
      },
      {
        id: 12040301,
        path: "income-expense/detail.html",
        name: "收支计划详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () =>
          import("../pages/admin/finance/income-expense-plan/detail")
      },
      {
        id: 12040500,
        path: "income-detail.html",
        name: "收支明细",
        type: MenuType.Page,
        component: () =>
          import("../pages/admin/finance/income-expense-plan-detail/index")
        // NotRefresh: true
      },
      {
        id: 12049100,
        path: "house-rent-detail.html",
        name: "账单",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/finance/house-rent-detail"),
        NotRefresh: true
      },
      {
        id: 12050000,
        path: "account/index.html",
        name: "财务账号",
        type: MenuType.Page,
        permission: ["page:finance:account"],
        component: () => import("../pages/admin/finance/account/index")
        // NotRefresh: true
      },
      {
        id: 12050100,
        path: "account/insert.html",
        name: "财务账号新增",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/finance/account/edit")
      },
      {
        id: 12050200,
        path: "account/update.html",
        name: "财务账号修改",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/finance/account/edit")
      }
    ]
  },
  {
    id: 12050000,
    path: "report",
    name: "报表管理",
    type: MenuType.Directory,
    permission: "directory:report",
    icon: <AreaChartOutlined />,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12050100,
        path: "achievement/index.html",
        name: "业绩报表",
        type: MenuType.Page,
        permission: ["page:report:achievement"],
        component: () => import("../pages/admin/report/achievement/index")
        // NotRefresh: true
      },
      {
        id: 12050500,
        path: "fiance/index.html",
        name: "财务报表",
        type: MenuType.Page,
        permission: ["page:report:fiance"],
        component: () => import("../pages/admin/report/fiance/index")
        // NotRefresh: true
      },
      {
        id: 12050600,
        path: "comprehensive/index.html",
        name: "综合报表",
        type: MenuType.Page,
        permission: ["page:report:comprehensive"],
        component: () => import("../pages/admin/report/comprehensive/index")
        // NotRefresh: true
      },

      {
        id: 12050700,
        path: "other/index.html",
        name: "其他报表",
        type: MenuType.Page,
        permission: ["page:report:other"],
        component: () => import("../pages/admin/report/other/index")
        // NotRefresh: true
      },
      {
        id: 12084240,
        path: "business-sys.html",
        name: "业务审批",
        type: MenuType.Page,
        permission: ["page:report:audit"],
        component: () => import("../pages/admin/other/business-audit/index")
        // NotRefresh: true
      },
      {
        path: "tenant-checkout.html",
        id: 12084241,
        type: MenuType.Page,
        name: "租客退房",
        hideMenu: true,
        component: () => import("../pages/admin/housing/tenant-checkout"),
        NotRefresh: true
      }
    ]
  },
  {
    id: 12060000,
    path: "wechat",
    name: "微信管理",
    type: MenuType.Directory,
    permission: "directory:wechat",
    icon: <WechatOutlined />,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12060100,
        path: "repair/index.html",
        name: "微信报修",
        type: MenuType.Page,
        permission: ["page:wechat:repair"],
        component: () => import("../pages/admin/wechat/repair/index")
        // NotRefresh: true
      },

      {
        id: 12060200,
        path: "check-out/index.html",
        name: "租客退房",
        type: MenuType.Page,
        permission: ["page:wechat:checkout"],
        component: () => import("../pages/admin/wechat/check-out/index")
        // NotRefresh: true
      },
      {
        id: 12060210,
        path: "check-out/detail.html",
        name: "租客退房详情",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/wechat/check-out/detail")
      },
      {
        id: 12060300,
        path: "complaint/index.html",
        name: "微信投诉",
        type: MenuType.Page,
        permission: ["page:wechat:complaint"],
        component: () => import("../pages/admin/wechat/complaint/index")
        // NotRefresh: true
      },
      {
        id: 12060310,
        path: "complaint/detail.html",
        name: "微信投诉详情",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/wechat/complaint/detail")
      },
      {
        id: 12060400,
        path: "collection/index.html",
        name: "微信收款",
        type: MenuType.Page,
        permission: ["page:wechat:collection"],
        component: () => import("../pages/admin/wechat/collection/index")
        // NotRefresh: true
      },
      {
        id: 12060313,
        path: "collection/detail.html",
        name: "微信收款详情",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/wechat/collection/detail")
      },
      {
        id: 12060500,
        path: "feedback/index.html",
        name: "房东反馈",
        type: MenuType.Page,
        permission: ["page:wechat:feedback"],
        component: () => import("../pages/admin/wechat/feedback/index")
        // NotRefresh: true
      },

      {
        id: 12060510,
        path: "feedback/detail.html",
        name: "房东反馈详情",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/wechat/feedback/detail")
      },
      {
        id: 12060600,
        path: "appointment/index.html",
        name: "预约看房",
        type: MenuType.Page,
        permission: ["page:wechat:appointment"],
        component: () => import("../pages/admin/wechat/appointment/index")
        // NotRefresh: true
      },
      {
        id: 12060601,
        path: "banner/index.html",
        name: "找房轮播图",
        type: MenuType.Page,
        permission: ["wx:house:banner"],
        component: () => import("../pages/admin/wechat/banner/index")
        // NotRefresh: true
      },
      {
        id: 12060610,
        path: "appointment/detail.html",
        name: "预约看房详情",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/wechat/appointment/detail")
      }
    ]
  },
  {
    id: 12070000,
    path: "other",
    name: "其他管理",
    type: MenuType.Directory,
    permission: "directory:other",
    icon: <BranchesOutlined />,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12071100,
        path: "room-maintenance-and-room-cleaning/index.html",
        name: "维修保洁",
        type: MenuType.Page,
        permission: ["page:other:maintenance-and-cleaning"],
        component: () =>
          import("../pages/admin/other/maintenance-and-cleaning/index")
        // NotRefresh: true
      },
      {
        id: 12071111,
        path: "cleaner-list/index.html",
        name: "保洁人员列表",
        type: MenuType.Page,
        permission: ["page:other:cleanList"],
        component: () => import("../pages/admin/other/cleaner-list/idnex")
        // NotRefresh: true
      },
      {
        id: 12071121,
        path: "cleaner-list/detail.html",
        name: "保洁人员详情",
        type: MenuType.Page,
        permission: ["page:other:maintenance-and-cleaning"],
        component: () => import("../pages/admin/other/cleaner-list/detail"),
        hideMenu: true
      },
      {
        id: 12071101,
        path: "maintenance-and-cleaning/detail.html",
        name: "维修详情",
        type: MenuType.Page,
        hideMenu: true,
        component: () =>
          import("../pages/admin/other/maintenance-and-cleaning/detail"),
        NotRefresh: true
      },
      {
        id: 12071102,
        path: "maintenance-and-cleaning/details.html",
        name: "保洁详情",
        type: MenuType.Page,
        hideMenu: true,
        component: () =>
          import("../pages/admin/other/maintenance-and-cleaning/detail"),
        NotRefresh: true
      },
      {
        id: 12071103,
        path: "maintenance-and-cleaning/detailss.html",
        name: "清洗详情",
        type: MenuType.Page,
        hideMenu: true,
        component: () =>
          import("../pages/admin/other/maintenance-and-cleaning/detail"),
        NotRefresh: true
      },
      {
        id: 12072100,
        path: "storage/index.html",
        name: "库房管理",
        type: MenuType.Page,
        permission: ["page:other:storage"],
        component: () => import("../pages/admin/other/storage/index")
        // NotRefresh: true
      },
      {
        id: 12072110,
        path: "storage/summarytable.html",
        name: "库房材料总表",
        type: MenuType.Page,
        permission: ["page:other:storage"],
        component: () => import("../pages/admin/other/storage/summarytable")
        // NotRefresh: true
      },
      {
        id: 12072200,
        path: "storage/warehouseMaterial.html",
        name: "管理材料",
        type: MenuType.Page,
        hideMenu: true,
        component: () =>
          import("../pages/admin/other/storage/warehouseMaterial")
      },
      {
        id: 12072201,
        path: "storage/totail.html",
        name: "材料明细汇总",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/other/storage/totail")
      },

      {
        id: 12072700,
        path: "storage/detail.html",
        name: "库存管理详情",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/other/storage/detail")
      },

      {
        id: 12073100,
        path: "out/index.html",
        name: "出门管理",
        type: MenuType.Page,
        permission: ["page:other:out"],
        component: () => import("../pages/admin/other/out/index")
        // NotRefresh: true
      },
      {
        id: 12073200,
        path: "out/insert.html",
        name: "出门管理新增",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/other/out/edit")
      },
      {
        id: 12073300,
        path: "out/update.html",
        name: "出门管理修改",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/other/out/edit")
      },
      {
        id: 12073400,
        path: "out/detail.html",
        name: "出门管理详情",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/other/out/detail")
      },
      {
        id: 12074100,
        path: "notice/index.html",
        name: "公告管理",
        type: MenuType.Page,
        permission: ["page:other:notice"],
        component: () => import("../pages/admin/other/notice/index")
        // NotRefresh: true
      },
      {
        id: 12074200,
        path: "notice/insert.html",
        name: "公告管理新增",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/other/notice/edit")
      },
      {
        id: 12074300,
        path: "notice/update.html",
        name: "公告管理修改",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/other/notice/edit")
      },
      {
        id: 12077000,
        path: "wait.html",
        name: "待办管理",
        type: MenuType.Page,
        permission: ["page:other:wait"],
        component: () => import("../pages/admin/other/wait/index")
        // NotRefresh: true
      },

      {
        id: 12075001,
        path: "house-recovery/detail.html",
        name: "房源退房详情",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/other/house-recovery/detail")
      },

      {
        id: 12075002,
        path: "house-recovery/print.html",
        name: "房源退房打印",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/other/house-recovery/print")
      },
      {
        id: 12076000,
        path: "recovery.html",
        name: "数据回收站",
        type: MenuType.Page,
        permission: ["page:other:deleteHis"],
        component: () => import("../pages/admin/other/recovery-sys/index")
        // NotRefresh: true
      },
      {
        id: 12078000,
        path: "book.html",
        name: "外部通讯录",
        type: MenuType.Page,
        permission: ["page:other:book"],
        component: () => import("../pages/admin/other/recovery-sys/book/index")
        // NotRefresh: true
      },
      // {
      //   id: 12078001,
      //   path: "file-manag.html",
      //   name: "文件管理",
      //   type: MenuType.Page,
      //   // permission: ["page:other:book"],
      //   component: () =>
      //     import("../pages/admin/other/recovery-sys/file-manag/index")
      // },
      {
        // NotRefresh: true
        id: 12079000,
        path: "outrepair.html",
        name: "外部维修保洁",
        type: MenuType.Page,
        permission: ["page:other:outrepair"],
        component: () => import("../pages/admin/other/outrepair/index")
      },
      {
        // NotRefresh: true
        id: 12079001,
        path: "selfform.html",
        name: "外部维修",
        type: MenuType.Page,
        permission: ["page:other:outrepair"],
        component: () => import("../pages/admin/flowChart/index")
      }
    ]
  },
  {
    id: 12070001,
    path: "file",
    name: "文件管理",
    type: MenuType.Directory,
    permission: "directory:other",
    icon: <PicCenterOutlined />,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12078001,
        path: "file-manag.html",
        name: "文件管理",
        type: MenuType.Page,
        // permission: ["page:other:book"],
        component: () =>
          import("../pages/admin/other/recovery-sys/file-manag/index")
      }
    ]
  },
  {
    id: 12080000,
    path: "system",
    name: "系统设置",
    type: MenuType.Directory,
    permission: "directory:system",
    icon: <ControlOutlined />,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12030100,
        path: "index.html",
        name: "门店管理",
        type: MenuType.Page,
        permission: ["page:system:store"],
        component: () => import("../pages/admin/store/index")
        // NotRefresh: true
      },
      {
        id: 12030200,
        path: "insert.html",
        name: "门店添加",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/store/edit")
      },
      {
        id: 12030300,
        path: "edit.html",
        name: "门店修改",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/store/edit")
      },
      {
        id: 12031100,
        path: "group.html",
        name: "门店分组",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/store/group")
      },
      {
        id: 12030110,
        path: "examine.html",
        name: "门店审批",
        type: MenuType.Page,
        permission: ["page:system:store"],
        component: () => import("../pages/admin/store/examine")
        // NotRefresh: true
      },
      {
        id: 12030210,
        path: "examineinsert.html",
        name: "门店审批添加",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/store/examineinsert")
      },
      {
        id: 12030211,
        path: "examinechange.html",
        name: "门店审批修改",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/store/examineinsert")
      },
      // {
      //   id: 12030110,
      //   path: "examine.html",
      //   name: "门店审批",
      //   type: MenuType.Page,
      //   permission: ["page:system:store"],
      //   component: () => import("../pages/admin/store/examine")
      //   // NotRefresh: true
      // },
      {
        id: 12030111,
        path: "change.html",
        name: "人员调店",
        type: MenuType.Page,
        permission: ["page:system:store:change"],
        component: () => import("../pages/admin/store/change")
        // NotRefresh: true
      },
      {
        id: 12080100,
        path: "staff/index.html",
        name: "员工设置",
        type: MenuType.Page,
        permission: ["page:system:staff"],
        component: () => import("../pages/admin/system/staff/index")
        // NotRefresh: true
      },
      {
        id: 12080200,
        path: "staff/insert.html",
        name: "员工添加",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/system/staff/edit")
      },
      {
        id: 12080300,
        path: "staff/edit.html",
        name: "员工修改",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/system/staff/edit")
      },

      {
        id: 12081101,
        path: "department/index.html",
        name: "部门设置",
        type: MenuType.Page,
        permission: ["page:system:role"],
        component: () => import("../pages/admin/system/department/index")
        // NotRefresh: true
      },
      {
        id: 12081201,
        path: "department/insert.html",
        name: "部门添加",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/system/department/edit")
      },
      {
        id: 12030310,
        path: "department/change.html",
        name: "部门修改",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/system/department/edit")
      },
      {
        id: 12081100,
        path: "role/index.html",
        name: "角色设置",
        type: MenuType.Page,
        permission: ["page:system:role"],
        component: () => import("../pages/admin/system/role/index")
        // NotRefresh: true
      },

      {
        id: 12081200,
        path: "role/insert.html",
        name: "角色添加",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/system/role/edit")
      },
      {
        id: 12081300,
        path: "role/edit.html",
        name: "角色修改",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/system/role/edit")
      },
      {
        id: 12082100,
        path: "dictionary.html",
        name: "字典设置",
        type: MenuType.Page,
        permission: ["page:system:dictionary"],
        component: () => import("../pages/admin/system/dictionary/index")
        // NotRefresh: true
      },
      {
        id: 12083100,
        path: "contract-setting/index.html",
        name: "合同设置",
        type: MenuType.Page,
        permission: ["page:system:contract-setting"],
        component: () => import("../pages/admin/system/contract/index")
        // NotRefresh: true
      },
      {
        id: 12083200,
        path: "contract-setting/insert.html",
        name: "合同添加",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/system/contract/edit")
      },
      {
        id: 12083300,
        path: "contract-setting/update.html",
        name: "合同修改",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/system/contract/edit")
      },

      {
        id: 12084100,
        path: "wechat/index.html",
        name: "微信设置",
        type: MenuType.Page,
        permission: ["page:system:wechat"],
        component: () => import("../pages/admin/system/wechat/index")
        // NotRefresh: true
      },
      {
        id: 12084111,
        path: "property/index.html",
        name: "楼盘字典设置",
        type: MenuType.Page,
        permission: ["page:property:dictionary"],
        component: () => import("../pages/admin/system/property/index")
        // NotRefresh: true
      },

      {
        id: 12084112,
        path: "purification/index.html",
        name: "清洗类目设置",
        type: MenuType.Page,
        permission: ["page:property:dictionary"],
        component: () => import("../pages/admin/system/purification/index")
        // NotRefresh: true
      },
      {
        id: 12084110,
        path: "wechat/insert.html",
        name: "微信设置新增",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/system/wechat/edit")
      },
      {
        id: 12084120,
        path: "wechat/update.html",
        name: "微信设置修改",
        hideMenu: true,
        type: MenuType.Page,
        component: () => import("../pages/admin/system/wechat/edit")
      },
      {
        id: 12084200,
        path: "wechat-template/index.html",
        name: "消息设置",
        type: MenuType.Page,
        permission: ["page:system:wechat-template"],
        component: () => import("../pages/admin/system/wechat-template/index")
        // NotRefresh: true
      },
      // {
      //   id: 12084210,
      //   path: "wechat-template/insert.html",
      //   name: "消息设置新增",
      //   type: MenuType.Page,
      //   hideMenu: true,
      //   component: () => import("../pages/admin/system/wechat-template/edit")
      // },
      // {
      //   id: 12084220,
      //   path: "wechat-template/update.html",
      //   name: "消息设置修改",
      //   type: MenuType.Page,
      //   hideMenu: true,
      //   component: () => import("../pages/admin/system/wechat-template/edit")
      // },
      {
        id: 12084500,
        path: "sms-template/index.html",
        name: "短信消息设置",
        type: MenuType.Page,
        hideMenu: true,
        permission: ["page:system:wechat-template"],
        component: () => import("../pages/admin/system/sms-template/index")
      },
      {
        id: 12084610,
        path: "sms-template/insert.html",
        name: "短信消息设置新增",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/system/sms-template/edit")
      },
      {
        id: 12084720,
        path: "sms-template/update.html",
        name: "短信消息设置修改",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/system/sms-template/edit")
      },
      {
        id: 12084721,
        path: "sms-template/list.html",
        name: "已发送短信列表",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/system//sms-template/list")
      },
      {
        id: 12084230,
        path: "house-sys.html",
        name: "房源设置",
        type: MenuType.Page,
        permission: ["page:system:house-setting"],
        component: () => import("../pages/admin/other/hose-sys/index")
        // NotRefresh: true
      },

      {
        id: 12084113,
        path: "blacklist/index.html",
        name: "黑名单设置",
        type: MenuType.Page,
        permission: ["page:system:blacklist"],
        component: () => import("../pages/admin/system/blacklist/index")
        // NotRefresh: true
      },
      {
        id: 12084330,
        path: "system/sys-log.html",
        name: "登陆日志",
        type: MenuType.Page,
        hideMenu: true,
        permission: ["page:system:user-log"],
        component: () => import("../pages/admin/system/log/index")
      }
    ]
  },
  {
    id: 12090000,
    path: "excel",
    name: "导入",
    type: MenuType.Page,
    hideMenu: true,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12090000,
        path: "index.html",
        name: "导入记录",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/excel-record/record/index")
      },
      {
        id: 12090000,
        path: "error.html",
        name: "导入错误信息",
        type: MenuType.Page,
        hideMenu: true,
        component: () => import("../pages/admin/excel-record/error/index")
      },
      {
        id: 12090000,
        path: "financeList.html",
        name: "导入财务列表",
        type: MenuType.Page,
        hideMenu: true,
        component: () =>
          import("../pages/admin/excel-record/record/financeList")
      },
      {
        id: 12090000,
        path: "broadbandRecord.html",
        name: "导入宽带列表",
        type: MenuType.Page,
        hideMenu: true,
        component: () =>
          import("../pages/admin/excel-record/record/broadbandRecord")
      },
      {
        id: 12090001,
        path: "Insurance.html",
        name: "导入列表",
        type: MenuType.Page,
        hideMenu: true,
        component: () =>
          import("../pages/admin/excel-record/record/broadbandRecord")
      }
    ]
  }
];
export const routes: Route[] = [
  {
    id: 11000000,
    path: "/",
    redirect: "/admin",
    type: MenuType.Page
  },
  {
    id: 12000000,
    path: "/admin",
    name: "首页",
    type: MenuType.Directory,
    component: () => import("../layout/logged-layout"),
    routes: AdminRoutes
  },
  {
    id: 13000000,
    name: "登录页面",
    path: "/login.html",
    type: MenuType.Page,
    disableKeepAlive: true,
    component: () => import("../pages/login")
  },

  {
    id: 14000000,
    name: "注册页面",
    path: "/register.html",
    disableKeepAlive: true,
    type: MenuType.Page,
    component: () => import("../pages/register")
  },
  {
    id: 15000000,
    name: "在线找房",
    path: "/findhouse.html",
    disableKeepAlive: true,
    type: MenuType.Page,
    component: () => import("../pages/findhouse")
  },
  {
    id: 16000000,
    name: "找房",
    path: "/mobilefindhouse.html",
    type: MenuType.Page,
    disableKeepAlive: true,
    component: () => import("../pages/mobilefindhouse")
  },
  {
    id: 17000000,
    name: "找房详情",
    path: "/mobilefindhousedetail.html",
    type: MenuType.Page,
    disableKeepAlive: true,
    component: () => import("../pages/mobilefindhouse/details")
  },
  {
    id: 18000000,
    name: "微信上传图片",
    path: "/wxuplaod.html",
    type: MenuType.Page,
    disableKeepAlive: true,
    component: () => import("../pages/wxupload")
  }
];

export interface Route {
  id: number;
  permission?: string[] | string;
  hideMenu?: boolean;
  icon?: ReactNode;
  path?: string;
  name?: string;
  routes?: Route[];
  component?: () => Promise<{ default: ComponentType }>;
  redirect?: string;
  type: MenuType;
  disableKeepAlive?: boolean;
  NotRefresh?: boolean;
}
